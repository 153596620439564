import tw from "twin.macro"
import styled from "@emotion/styled"
import React from "react"
import { ListContainerInterface } from "../../@types/components/posts/listContainer"
import Title from "../title"

const ListContainer: React.FC<ListContainerInterface> = ({
  children,
  title,
}) => {
  const TitleContainer = styled.section`
    ${tw`md:w-full`};
  `

  const Section = styled.section`
    ${tw`
      flex flex-col items-center self-center
      md:w-8/12 md:items-start
      xl:w-6/12
    `}
  `

  const Posts = styled.section`
    ${tw`
      mt-4
    `}
  `

  return (
    <Section>
      <TitleContainer>
        <Title title={title} />
      </TitleContainer>
      <Posts>{children}</Posts>
    </Section>
  )
}

export default ListContainer
