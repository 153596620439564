import React from "react"
import { PostDateInterface } from "../../@types/components/posts/postDate"
import tw from "twin.macro"
import styled from "@emotion/styled"

const PostDate: React.FC<PostDateInterface> = ({ dateTime, children }) => {
  const Time = styled.time`
    ${tw`
      text-base w-6/12 font-date font-light
      md:text-sm
      xl:text-base
    `};
    color: #4a5568;
  `

  return (
    <Time dateTime={dateTime} className={"dt-published"}>
      {children}
    </Time>
  )
}

export default PostDate
