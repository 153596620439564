import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import List from "../components/posts/list"
import SEO from "../components/seo"
import { OverviewInterface } from "../@types/pages/overview"

export const query = graphql`
  query {
    allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { fields: { type: { eq: "project" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            date
            formattedDate: date(formatString: "MMMM Do, YYYY")
            title
          }
        }
      }
    }
  }
`

const ProjectsPage: React.FC<OverviewInterface> = ({ data }) => {
  return (
    <Layout>
      <SEO title={"Projects"} />
      <List
        title={"Projects"}
        posts={data.allMdx.edges.map(({ node }) => {
          return {
            title: node.frontmatter.title,
            date: node.frontmatter.date,
            formattedDate: node.frontmatter.formattedDate,
            slug: node.fields.slug,
          }
        })}
      />
    </Layout>
  )
}

export default ProjectsPage
