import React from "react"
import { Link } from "gatsby"

import styled from "@emotion/styled"
import { ListInterface } from "../../@types/components/posts/list"
import tw from "twin.macro"
import PostDate from "./postDate"
import ListContainer from "./listContainer"

const List: React.FC<ListInterface> = ({ title, posts }) => {
  const Post = styled(Link)`
    ${tw`flex flex-col hover:underline`};
  `

  const PostBody = styled.article`
    ${tw`
      mt-4
      md:mt-6 
      xl:mt-8
    `};
  `

  const PostTitle = styled.span`
    ${tw`
      font-bold text-xl 
      md:text-base
      xl:text-2xl
      `}
  `

  return (
    <ListContainer title={title}>
      {posts.map(({ date, slug, title, formattedDate }, i) => (
        <PostBody key={"post-" + i}>
          <Post to={slug}>
            <PostDate dateTime={date}>{formattedDate}</PostDate>
            <PostTitle>{title}</PostTitle>
          </Post>
        </PostBody>
      ))}
    </ListContainer>
  )
}

export default List
